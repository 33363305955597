<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->
    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/map.svg')"
      :title="`产品服务背景`"
      :en_title="`Product Service Background`"
    ></Paragraph>
    <Titlestrlist
      :datalist="datalist"
    ></Titlestrlist>

    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/db.svg')"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <Titlestrlist
      :datalist="datalist1"
    ></Titlestrlist>

    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/db.svg')"
      :title="`产品功能`"
      :en_title="`Product Features`"
    ></Paragraph>
    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 20%;
        margin-right: 20%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl.slice(0,3)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 20%;
        margin-right: 20%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl.slice(3,6)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 20%;
        margin-right: 20%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl.slice(6,9)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblockleft';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
    Lettertitleblock,
  },
  data() {
    return {
      title: '私钥托管服务',
      body:
        '将加密数据及数据恢复密钥相关起来,数据恢复密钥没有必要是直接解密的密钥,不过通过它能获取解密密钥。'
        + '按理数据恢复密钥被可托付的委托人拥有。一个密钥也可能在数个委托人中被拆分为多个分量,分别通过多个委托人持有。'
        + '授权机构(如调查或情报机构)可由恰当的程序(如获得法院的许可),从多个委托人手里恢复密钥。',
      image: require('@/assets/image/chain/va/escrow/panel.webp'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/escrow/12_01.jpg'),
        require('@/assets/image/chain/va/escrow/12_02.jpg'),
        require('@/assets/image/chain/va/escrow/12_03.jpg'),
      ],
      datalist: [
        {
          title: '身份信息认证',
          content:
            '在商务活动里,利用数字签名就能验证自己的身份以防抵赖。但当用户变了密码,其就能抵赖没有实施过这个商务活动。为了防止此抵赖,有如下几种办法:一种是用户改密码的时候须对CA进行说明,不可以私自改变;另一种是密钥托管,当用户抵赖时,这t个托管人则可以展示他们存储的密钥合成用户的密钥,让用户不能抵赖。',
        },
        {
          title: '政府监听',
          content:
            '政府、法律有关部门或合法的第三者为追踪、获取犯罪嫌疑人的通信,需获得通信双方的密钥。此时合法的监听者可由用户的委托人收集密钥片之后获取用户密钥,从而监听。',
        },
        {
          title: '用户密钥恢复',
          content:
            '用户忘了密钥想恢复时,可在委托人那收集密钥片进行密钥的恢复。',
        },
      ],
      datalist1: [
        {
          title: '安全与合规',
          content:
            '经过严格的安全设计和审核，保证您的密钥得到最严格的保护。通过使用具备中国国家密码管理局或FIPS认证资质的托管密码机、配置自定义密钥轮转策略、在访问控制服务管理身份和权限、在操作审计服务追踪密钥的使用情况，您可以快速满足相应的监管与合规要求。如果您将密钥的使用日志从操作审计服务输出到对象存储或日志服务，则可以进一步和SIEM解决方案进行集成获得额外的分析和威胁检测能力。',
        },
        {
          title: '可用、可靠、弹性',
          content:
            '在每个地域构建了多可用区冗余的密码计算能力，保证发起的请求可以得到低延迟处理。您可以根据需要，在不同地域的创建足够的密钥，而不必担心底层设施的扩容或缩容。您还可以通过BYOK的方式，保持一份密钥的拷贝从而获得更多的持久性。',
        },
        {
          title: '轻松自定义加密和数字签名',
          content:
            '对密码技术和HSM接口进行了抽象，通过简单易用的接口，帮助您实现自定义的数据加密保护，让安全与合规需求与业务系统紧密融合，进一步减小恶意者对敏感数据的攻击面。您还可以通过非对称的密钥对，实现数字签名来保护关键数据或者消息的完整性。',
        },
        {
          title: '完全托管',
          content:
            '为您提供了密钥托管和密码服务，负责密码基础设施的完全托管，保证服务和设施的可用性，安全性以及可靠性。您只需要专注于密钥的生命周期和权限策略等管理任务，以及数据加解密和数字签名验签等业务场景。',
        },
      ],
      datalistl: [
        {
          title: '生成密钥或外部导入',
          imgsrc: 'A',
          list: [],
        },
        {
          title: '生命周期管理和自动化轮转',
          imgsrc: 'B',
          list: [],
        },
        {
          title: '3A:认证、授权与审计',
          imgsrc: 'C',
          list: [],
        },
        {
          title: '密码模块检测认证与合规',
          imgsrc: 'D',
          list: [],
        },
        {
          title: '密钥的安全生成',
          imgsrc: 'E',
          list: [],
        },
        {
          title: '密钥的硬件保护',
          imgsrc: 'F',
          list: [],
        },
        {
          title: '信封加密技术',
          imgsrc: 'G',
          list: [],
        },
        {
          title: '可认证加密',
          imgsrc: 'H',
          list: [],
        },
        {
          title: '数字签名验签',
          imgsrc: 'I',
          list: [],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
